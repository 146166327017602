var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { staticClass: "main-auth-redirect" },
        [
          _c(
            "a-col",
            {
              staticStyle: {
                height: "100%",
                "text-align": "center",
                top: "30%",
              },
              attrs: { span: 24 },
            },
            [
              _vm.showLoading
                ? _c("a-spin", {
                    attrs: { size: "large", tip: "正在验证登录..." },
                  })
                : _vm._e(),
              _vm.showError
                ? _c("a-result", {
                    attrs: { status: "error", title: "token验证失败" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "extra",
                          fn: function () {
                            return [
                              _c(
                                "a-button",
                                {
                                  key: "console",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.goBack },
                                },
                                [_vm._v(" 返回 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      281800616
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }