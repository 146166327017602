<template>
  <div>
    <a-row class="main-auth-redirect">
      <a-col :span="24" style="height:100%;text-align:center;top:30%;">
        <a-spin v-if="showLoading" size="large" tip="正在验证登录..."/>
        <a-result v-if="showError" status="error" title="token验证失败">
          <template #extra>
            <a-button key="console" type="primary" @click="goBack">
              返回
            </a-button>
          </template>
        </a-result>

      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Result } from 'ant-design-vue'
import { getAuthToken } from '@/api/openapi/authToken'
import storage from 'store'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
export default {
  data () {
    return {
      showWarn: false,
      showError: false,
      showLoading: true
    }
  },
  components: {
    AResult: Result
  },
  created () {
    // const token = this.$route.query.token
    // const callback = this.$route.query.callback
    this.getAuthToken()
  },
  methods: {
    ...mapActions(['AuthToken']),
    refresh() {
        // 刷新当前页面
        this.showLoading = true
        this.showError = false
        this.getAuthToken()
      },
      getAuthToken() {
        const callback = this.$route.query.callback
        const url = callback && decodeURIComponent(callback)
      const token = storage.get(ACCESS_TOKEN)
        console.log('token', token)
        console.log('url ***', url)
        getAuthToken({ token: token })
          .then((res) => {
            console.log('getAuthToken', res)
            this.$router.push(url + '?token=' + res.data.token)
            this.showLoading = false
            this.showError = false
          })
          .catch((err) => {
            console.log('getAuthToken', err)
            this.showError = true
            this.showLoading = false
          })
      }
  }
}
</script>

<style lang="less">
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
}
.main-auth-redirect {
  margin: 0;
  height: 100%;
  width: 100%;
  .login-right {
    background-image: url(~@/assets/images/login-right-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: inherit;
    background-position: center 0;
  }
  .main {
    min-width: 260px;
    width: 100%;
    height:100%;
    background-color: #FFFFFF;
    padding: 20% 50px;
  }
  .ant-input{
    border-radius:20px;
    background: #FFFFFF;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.85);
  }
  .has-error .ant-input-affix-wrapper .ant-input, .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #FFF;
  }
  .ant-btn-lg{
    border-radius:20px;
  }
  .login-header {
    width: 42%;
    padding-bottom:1em;
    font-size: larger;
    font-weight: 800;
    color: #949494;
    img{
      height: auto;
      width:auto;
      max-height:60%;
      max-width: 60%;
    }
  }
  .logo-right{
    width: 76%;
    margin: 1em auto 0px auto;
    text-align: center;
    a {
      color: #fff;
      font-size: xxx-large;
      font-weight: 900;
      text-shadow: 4px 3px 4px #013a07;
    }
    a:hover {
      color: #fff;
    }
    img{
      height: auto;
      width:auto;
      max-height:100%;
      max-width: 100%;
    }
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
      background: #013a07;
      border: 1px solid #013a07;
      color: #ffffff;
    }

    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #013a07;
        }
      }

      .register {
        float: right;
      }
    }
  }
}

</style>
